import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Header from "./generic/Header";
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./generic/Footer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { config } from "../config/config";
import { useCookies } from "react-cookie";
import { indiaTopCities } from "../common/common";
import BannerSection from "./home-components/BannerSection";
import Service from "./home-components/Service";

import ExclusivePropertySection from "./home-components/ExclusivePropertySection";
import LatestPropertiesSection from "./home-components/LatestPropertiesSection";

function Home() {
  const [cookies, setCookies] = useCookies();
  const sliderRef2 = useRef(null);
  const [slidesToShow1, setSlidesToShow1] = useState(4);
  const [slidesToShow2, setSlidesToShow2] = useState(8);
  const [leadModal, setLeadModal] = useState(false);
  const handeChangeModal = () => setLeadModal((prevState) => !prevState);

  const settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow1,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null,
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow2,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null,
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 900) {
        setSlidesToShow1(4);
        setSlidesToShow2(8);
      } else if (window.innerWidth >= 600) {
        setSlidesToShow1(2);
        setSlidesToShow2(6);
      } else if (window.innerWidth >= 400) {
        setSlidesToShow1(1);
        setSlidesToShow2(3);
      } else if (window.innerWidth >= 200) {
        setSlidesToShow1(1);
        setSlidesToShow2(3);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setCity = (value) => {
    // Create updated cookie value
    const updatedCookieValue = JSON.stringify({
      ...cookies[config.preferencesCookie],
      city: value,
    });

    setCookies(config.preferencesCookie, updatedCookieValue, {
      path: "/",
      maxAge: 3000000,
      sameSite: "strict",
    });
  };

  return (
    <>
      <Header />
      <BannerSection />

      <ExclusivePropertySection />
      {/* below the section is for Hot Properties in India start */}

      <LatestPropertiesSection />

      {/* below the section is Find Your Dream Properties In Your Preferred City start */}
      <Box
        component="section"
        sx={{
          backgroundColor: "#F5F5F5",
          py: { md: 10, xs: 5 },
        }}
      >
        <Box className="primary-heading-container">
          <Typography variant="h2" className="section-primary-heading">
            Find Your Dream Properties In{" "}
            {cookies[config.preferencesCookie]?.city
              ? cookies[config.preferencesCookie]?.city
              : "Your Preferred City"}
          </Typography>
        </Box>
        <Box
          sx={{
            py: { md: 4, xs: 2 },
            width: "100%",
          }}
        >
          <Container maxWidth={"md"} sx={{ position: "relative" }}>
            {/* Custom "Previous" button */}
            <Box
              className="custom-arrow custom-prev-arrow"
              onClick={() => {
                if (sliderRef2.current) {
                  sliderRef2.current.slickPrev(); // Go to the previous slide
                }
              }}
              sx={{
                position: "absolute",
                top: "50%",
                ml: { md: -4, xs: -3 },
                display: "flex",
                alignContent: "center",
                transform: "translateY(-50%)",
                p: 1,
                backgroundColor: "white",
                borderRadius: "50%", // Set the borderRadius to create a circle
                maxWidth: { md: "150px", xs: "60px" }, // Adjust the width and height to make it circular
                color: "#8E8F8F",
                boxShadow:
                  "0px 2.6658482551574707px 5.331696510314941px rgba(0, 0, 0, 0.15)",
              }}
            >
              <ArrowBackIosNewIcon
                sx={{
                  width: { md: "17px", xs: "10px" },
                  height: { md: "16px", xs: "10px" },
                }}
              />
            </Box>

            <Slider ref={sliderRef2} {...settings2}>
              {/* {topAdmission.map((property) => ( */}
              {indiaTopCities?.map((item) => (
                //  <div key={property.id}>
                <div key={item.name}>
                  <Card
                    sx={{
                      margin: "0 5px",
                      boxShadow: "0px 2.4px 4.8px 0px rgba(0, 0, 0, 0.15)",
                      borderRadius: "10px",
                      border: "1px solid var(--Text-Color-Grey-Light, #555656)",
                      background: "#FFF",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        "&:hover": {
                          backgroundColor: "#eeeeee",
                        },
                      }}
                      onClick={() => {
                        setCity(item.name);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          m: "auto",
                          width: { md: "50px", xs: "30px" },
                          height: { md: "50px", xs: "30px" },
                        }}
                      >
                        <img src={item.svg} alt={item.name} />
                      </Box>

                      <Box
                        sx={{
                          color: "#555656",
                          fontSize: { md: "16px", xs: "8px" },
                          fontWeight: { md: 400, xs: 500 },
                          lineHeight: "normal",
                          fontFamily: "Mulish, sans-serif",
                          mt: { md: "5px", xs: "0px" },
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{ fontSize: { sm: "0.75rem", xs: "12px" } }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </Slider>

            {/* Custom "Next" button */}
            <Box
              className="custom-arrow custom-next-arrow"
              onClick={() => {
                if (sliderRef2.current) {
                  sliderRef2.current.slickNext(); // Go to the next slide
                }
              }}
              sx={{
                position: "absolute",
                top: "50%",
                display: "flex",
                alignContent: "center",
                right: { md: -5, xs: "-7px" },
                transform: "translateY(-50%)",
                p: 1,
                backgroundColor: "white",
                borderRadius: "50%", // Set the borderRadius to create a circle
                maxWidth: { md: "150px", xs: "60px" }, // Adjust the width and height to make it circular
                color: "#8E8F8F",
                boxShadow:
                  "0px 2.6658482551574707px 5.331696510314941px rgba(0, 0, 0, 0.15)",
              }}
            >
              <ArrowForwardIosIcon
                sx={{
                  width: { md: "17px", xs: "9px" },
                  height: { md: "17px", xs: "9px" },
                }}
              />
            </Box>
          </Container>
        </Box>
      </Box>
      {/* <PopularLocality /> */}
      <Service />
      <Footer />
    </>
  );
}

export default Home;
