// WhatWeWillDo.js
import React, { useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowForward, ArrowBack } from "@mui/icons-material";

const services = [
  {
    iconPath: "/servicePhotos/icons/house-deep-cleaning.png",
    title: "House Deep Cleaning",
    link: "/deep-cleaning-service-in-",
  },
  {
    iconPath: "/servicePhotos/icons/bathroom-cleaning.png",
    title: "Bathroom",
    link: "/bathroom-cleaning-service-in-",
  },
  {
    iconPath: "/servicePhotos/icons/kitchen-cleaning.png",
    title: "Kitchen",
    link: "/kitchen-cleaning-service-in-",
  },
  {
    iconPath: "/servicePhotos/icons/chimney-cleaning.png",
    title: "Chimney",
    link: "/chimney-cleaning-service-",
  },
  {
    iconPath: "/servicePhotos/icons/carpet-cleaner.png",
    title: "Carpet",
    link: "/carpet-cleaning-service-in-",
  },
  {
    iconPath: "/servicePhotos/icons/sofa-cleaning.png",
    title: "Sofa",
    link: "/sofa-cleaning-service-in-",
  },
  {
    iconPath: "/servicePhotos/icons/mattress-cleaning.png",
    title: "Mattress",
    link: "/mattress-cleaning-service-in-",
  },
  {
    iconPath: "/servicePhotos/icons/window-cleaning.png",
    title: "Window",
    link: "/window-cleaning-service-in-",
  },
  {
    iconPath: "/servicePhotos/icons/balcony-cleaning.png",
    title: "Balcony",
    link: "/balcony-cleaning-service-in-",
  },
  {
    iconPath: "/servicePhotos/icons/chair-cleaning.png",
    title: "Chair",
    link: "/chair-cleaning-service-in-",
  },
  // Add more services as needed
];

const WhatWeWillDo = () => {
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const ITEMS_TO_SHOW = isMobile ? 2 : 5; // Show 2 items on mobile, 5 on larger screens

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + ITEMS_TO_SHOW, services.length - ITEMS_TO_SHOW)
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - ITEMS_TO_SHOW, 0));
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          scrollSnapType: "x mandatory",
          "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar for webkit browsers
        }}
      >
        {services
          .slice(currentIndex, currentIndex + ITEMS_TO_SHOW)
          .map((service, index) => (
            <Box
              key={index}
              sx={{
                flex: "0 0 auto",
                width: `calc(100% / ${ITEMS_TO_SHOW})`,
                padding: 2,
                scrollSnapAlign: "start",
                textAlign: "center",
              }}
            >
              <Paper elevation={3} sx={{ padding: 2, borderRadius: 2 }}>
                <Box sx={{ marginBottom: 2 }}>
                  <img
                    src={service.iconPath}
                    alt={`${service.title}`}
                    height="40px"
                    width="40px"
                  />
                </Box>
                <Typography variant="h6" sx={{ fontSize: "16px" }}>
                  {service.title}
                </Typography>
              </Paper>
            </Box>
          ))}
      </Box>
      {services.length > ITEMS_TO_SHOW && (
        <>
          <IconButton
            onClick={handlePrev}
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "red",
              color: "white",
              "&:hover": { backgroundColor: "#ff5252" },
              "&:disabled": {
                backgroundColor: "rgba(255, 0, 0, 0.5)", // Semi-transparent red when disabled
                cursor: "not-allowed",
              },
            }}
            disabled={currentIndex === 0}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "red",
              color: "white",
              "&:hover": { backgroundColor: "#ff5252" },
              "&:disabled": {
                backgroundColor: "rgba(255, 0, 0, 0.5)", // Semi-transparent red when disabled
                cursor: "not-allowed",
              },
            }}
            disabled={currentIndex + ITEMS_TO_SHOW >= services.length}
          >
            <ArrowForward />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default WhatWeWillDo;
