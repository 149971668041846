import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PackagesList from "./components/packages/PackagesList";
import MyPackages from "./components/packages/MyPackages";
import PackageBuy from "./components/packages/PackageBuy";
import UsersList from "./components/user/UsersList";
import PackageAdd from "./components/packages/PackageAdd";
import PackageUpdate from "./components/packages/PackageUpdate";
import UserAdd from "./components/user/UserAdd";
import UserUpdate from "./components/user/UserUpdate";
import Home from "./components/Home";
import Login from "./components/login/Login";
import ServiceCharges from "./components/ServiceCharges";
import EditProperty from "./components/properties/propertiesList/edit-property/EditProperty";
import PropertyList from "./components/end-user/property-list-filter/PropertyList";
import MyPropertyLists from "./components/properties/propertiesList/dashboard-property-list/MyPropertyLists";
import PropertyListingType from "./components/properties/post-properties/PropertyListingType";
import Logout from "./components/login/Logout";
import DashboardAnalytics from "./components/generic/dashboard/DashboardAnalytics";
import { CookiesProvider } from "react-cookie";
import AboutUs from "./components/static-page/AboutUs";
import ContactUs from "./components/static-page/ContactUs";
import NonRefundPolicy from "./components/static-page/NonRefundPolicy";
import PrivacyAndPolicy from "./components/static-page/PrivacyAndPoicy";
import TermsAndConditions from "./components/static-page/TermsAndConditions";
import LeadsList from "./components/leads/leadsList";
import { Provider } from "react-redux";
import store from "./global/redux/store";
import ProtectedRoute from "./components/functional-component/ProtectedRoute";
import Register from "./components/login/Register";
import OverViewDetails from "./components/end-user/PropertyDetails/OverViewDetails";
import ViewProperty from "./components/properties/propertiesList/ViewProperty";
import CreateLead from "./components/leads/CreateLead";
import Litigation from "./components/home-components/services/Litigation";
import ResetPassword from "./components/login/reset-password/ResetPassword";
import AdminPendingPackages from "./components/packages/AdminPendingPackages";
import AssociateList from "./components/operator/AssociateList";
import GroupingProperties from "./components/properties/grouping/GroupingProperties";
import ContactUsData from "./components/leads/ContactUsData";
import AddBlog from "./components/blog/AddBlog";
import ManageBlogs from "./components/blog/ManageBlogs";
import ReviewBlog from "./components/blog/ReviewBlog";
import EditBlog from "./components/blog/EditBlog";
import BlogList from "./components/blog/BlogList";
import ViewBlog from "./components/blog/ViewBlog";
import Address from "./components/address-manager/Address";
import MyProfile from "./components/end-user/MyProfile";
import NotFoundPage from "./components/static-page/NotFoundPage";
import NetworkStatusCheck from "./components/static-page/NoInternetMessage";
import Fabrication from "./components/home-components/services/Fabrication";
import MosquitoNetting from "./components/home-components/services/MosquitoNetting";
import WaterPurifier from "./components/home-components/services/WaterPurifier";
import Geyser from "./components/home-components/services/Geyser";
import AirCrackFilling from "./components/home-components/services/AirCrackFilling";
import WaterProofing from "./components/home-components/services/WaterProofing";
import WelcomePage from "./components/static-page/WelcomePage";
import AgentList from "./components/operator/AgentList";
import AgentReg from "./components/login/AgentReg";
import { services } from "./common/common";

const App = () => {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
          <NetworkStatusCheck>
            <Routes>
              {/* <Route path="/post-sale-property" element={<AddSaleProperty />} /> */}
              {/* <Route path="/register-agent/:userId" element={<Register />} /> */}
              <Route path="/register" element={<Register />} />
              <Route path="/agent-register" element={<AgentReg />} />
              {/* <Route path="/:id?" element={<Home />} /> */}

              {/* below the route for public users  */}
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/service-charges" element={<ServiceCharges />} />
              <Route path="/non-refund-policy" element={<NonRefundPolicy />} />
              <Route
                path="/terms-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
              <Route path="/contact-us-data" element={<ContactUsData />} />
              <Route path="/welcome" element={<WelcomePage />} />

              {/* below the route for registered users  */}
              <Route
                path="/address-manager"
                element={
                  <ProtectedRoute>
                    <Address />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/add-blog"
                element={
                  <ProtectedRoute>
                    <AddBlog />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/review-blog/:id"
                element={
                  <ProtectedRoute>
                    <ReviewBlog />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/blog-list"
                element={
                  <ProtectedRoute>
                    <ManageBlogs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/edit-blog"
                element={
                  <ProtectedRoute>
                    <EditBlog />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-profile"
                element={
                  <ProtectedRoute>
                    <MyProfile />
                  </ProtectedRoute>
                }
              />
              <Route path="/blogs" element={<BlogList />} />
              <Route path="/blog/:slug/:id" element={<ViewBlog />} />
              <Route
                path="/post-property"
                element={
                  <ProtectedRoute>
                    <PropertyListingType />
                  </ProtectedRoute>
                }
              />
              {/* below the route for grouping properties  */}
              <Route
                path="/grouping-property"
                element={
                  <ProtectedRoute>
                    <GroupingProperties />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/operator-associate-list"
                element={
                  <ProtectedRoute>
                    <AssociateList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/operator-agent-list"
                element={
                  <ProtectedRoute>
                    <AgentList />
                  </ProtectedRoute>
                }
              />
              <Route path="/my-property-lists" element={<MyPropertyLists />} />
              <Route
                path="/property-edit/:id"
                element={
                  <ProtectedRoute>
                    <EditProperty />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/view-property/:propertyId"
                element={
                  <ProtectedRoute>
                    <ViewProperty />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-add"
                element={
                  <ProtectedRoute>
                    <UserAdd />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users-list"
                element={
                  <ProtectedRoute>
                    <UsersList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/add-package"
                element={
                  <ProtectedRoute>
                    <PackageAdd />
                  </ProtectedRoute>
                }
              />
              <Route path="/packages-list" element={<PackagesList />} />
              <Route
                path="/user-update/:id"
                element={
                  <ProtectedRoute>
                    <UserUpdate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/buy-package"
                element={
                  <ProtectedRoute>
                    <PackageBuy />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-packages"
                element={
                  <ProtectedRoute>
                    <MyPackages />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/update-package/:id"
                element={
                  <ProtectedRoute>
                    <PackageUpdate />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/leads-list"
                element={
                  <ProtectedRoute>
                    <LeadsList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-lead"
                element={
                  <ProtectedRoute>
                    <CreateLead />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pending-packages-list"
                element={
                  <ProtectedRoute>
                    <AdminPendingPackages />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <DashboardAnalytics />
                  </ProtectedRoute>
                }
              />

              {/* below the two route for property list and property details  */}
              <Route
                path="/property/:listingType?"
                element={<PropertyList />}
              />

              <Route
                path="/property/:listingType?/:propertyName?/:title?/:propertyId"
                element={<OverViewDetails />}
              />

              {/*Code for the Services Offers */}
              <Route path="/litigation" element={<Litigation />} />
              <Route
                path="/fabrication-service/:city"
                element={<Fabrication />}
              />
              <Route
                path="/mosquito-netting-service/:city"
                element={<MosquitoNetting />}
              />
              <Route
                path="/water-purifier-service/:city"
                element={<WaterPurifier />}
              />
              <Route path="/geyser-service/:city" element={<Geyser />} />
              <Route
                path="/air-crack-filling-service/:city"
                element={<AirCrackFilling />}
              />
              <Route
                path="/water-proofing-service/:city"
                element={<WaterProofing />}
              />
              {services.map((item) => (
                <Route
                  key={item.name}
                  path={item.navigate + ":city"}
                  element={item.component}
                />
              ))}

              {services
                .flatMap((item) => item.childService || []) // Collects all childService elements
                .map((child) => (
                  <Route
                    key={child.name}
                    path={child.navigate + ":city"}
                    element={child.component}
                  />
                ))}

              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="*" element={<NotFoundPage />} />
              {/* <Route path="/sitemap" component={<Sitemap />} /> */}
            </Routes>
          </NetworkStatusCheck>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  );
};

export default App;
